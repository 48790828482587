import routerOptions0 from "/app/node_modules/.pnpm/nuxt@3.12.4_@types+node@22.4.0_eslint@8.57.0_ioredis@5.4.1_typescript@5.5.4_vite@5.4.1_vue-tsc@2.0.29/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/app/node_modules/.pnpm/@nuxt+ui-pro@1.4.1_vite@5.4.1_vue@3.4.38/node_modules/@nuxt/ui-pro/modules/pro/runtime/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}