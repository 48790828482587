export default defineAppConfig({
	ui: {
		primary: 'blue',
		gray: 'slate',
		button: {
			default: {
				loadingIcon: 'svg-spinners:180-ring-with-bg',
			},
			icon: {
				loading: 'animate-none',
			},
		},
		input: {
			default: {
				loadingIcon: 'svg-spinners:180-ring-with-bg',
			},
			icon: {
				loading: 'animate-none',
			},
		},
		select: {
			default: {
				loadingIcon: 'svg-spinners:180-ring-with-bg',
			},
			icon: {
				loading: 'animate-none',
			},
		},
		commandPalette: {
			default: {
				loadingIcon: 'svg-spinners:180-ring-with-bg',
			},
			icon: {
				loading: 'animate-none',
			},
		},
		table: {
			default: {
				loadingState: {
					icon: 'svg-spinners:180-ring-with-bg',
				},
			},
			loadingState: {
				icon: 'animate-none',
			},
		},
	},
});
