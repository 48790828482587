import * as Sentry from '@sentry/vue';

export default defineNuxtPlugin((nuxtApp) => {
	const router = useRouter();
	const auth = useAuthStore();

	const {
		public: { sentry },
	} = useRuntimeConfig();

	if (!sentry.dsn) {
		return;
	}

	Sentry.init({
		app: nuxtApp.vueApp,
		dsn: sentry.dsn,
		environment: sentry.environment,
		integrations: [
			Sentry.browserTracingIntegration({ router }),
			Sentry.browserProfilingIntegration(),
			Sentry.replayIntegration(),
		],
		tracesSampleRate: 1,
		tracePropagationTargets: ['localhost', 'https://my.tpsa.app'],
		replaysSessionSampleRate: 1.0,
		replaysOnErrorSampleRate: 1.0,
		profilesSampleRate: 1.0,
	});

	if(auth.user){
		Sentry.setUser({
			id: auth.user.id,
			email: auth.user.email,
		});
	}

	watch(() => auth.user, (user) => {
		if (user) {
			Sentry.setUser({
				id: user.id,
				email: user.email,
			});
		} else {
			Sentry.setUser(null);
		}
	});
});
