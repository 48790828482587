import revive_payload_client_Uf99fU0R6H from "/app/node_modules/.pnpm/nuxt@3.12.4_@types+node@22.4.0_eslint@8.57.0_ioredis@5.4.1_typescript@5.5.4_vite@5.4.1_vue-tsc@2.0.29/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_5A4gSxIgkz from "/app/node_modules/.pnpm/nuxt@3.12.4_@types+node@22.4.0_eslint@8.57.0_ioredis@5.4.1_typescript@5.5.4_vite@5.4.1_vue-tsc@2.0.29/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Vyzq2DWJ15 from "/app/node_modules/.pnpm/nuxt@3.12.4_@types+node@22.4.0_eslint@8.57.0_ioredis@5.4.1_typescript@5.5.4_vite@5.4.1_vue-tsc@2.0.29/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_Mu3yatHUFz from "/app/node_modules/.pnpm/nuxt@3.12.4_@types+node@22.4.0_eslint@8.57.0_ioredis@5.4.1_typescript@5.5.4_vite@5.4.1_vue-tsc@2.0.29/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_qeC8jkeayl from "/app/node_modules/.pnpm/nuxt@3.12.4_@types+node@22.4.0_eslint@8.57.0_ioredis@5.4.1_typescript@5.5.4_vite@5.4.1_vue-tsc@2.0.29/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_14gNpMUP8B from "/app/node_modules/.pnpm/nuxt@3.12.4_@types+node@22.4.0_eslint@8.57.0_ioredis@5.4.1_typescript@5.5.4_vite@5.4.1_vue-tsc@2.0.29/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_1ehcmAJmE9 from "/app/node_modules/.pnpm/nuxt@3.12.4_@types+node@22.4.0_eslint@8.57.0_ioredis@5.4.1_typescript@5.5.4_vite@5.4.1_vue-tsc@2.0.29/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_APU4xylc9R from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.3_typescript@5.5.4_vue@3.4.38/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/apps/app/.nuxt/components.plugin.mjs";
import prefetch_client_tAJdqXgwcq from "/app/node_modules/.pnpm/nuxt@3.12.4_@types+node@22.4.0_eslint@8.57.0_ioredis@5.4.1_typescript@5.5.4_vite@5.4.1_vue-tsc@2.0.29/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import session_client_2XxyMLMOkA from "/app/node_modules/.pnpm/nuxt-auth-utils@0.3.4/node_modules/nuxt-auth-utils/dist/runtime/app/plugins/session.client.js";
import slideovers_Td48QsuqmF from "/app/node_modules/.pnpm/@nuxt+ui@2.18.4_vite@5.4.1_vue@3.4.38/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_O8BdabQzL2 from "/app/node_modules/.pnpm/@nuxt+ui@2.18.4_vite@5.4.1_vue@3.4.38/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_HX0rgKuW8Z from "/app/node_modules/.pnpm/@nuxt+ui@2.18.4_vite@5.4.1_vue@3.4.38/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_zxnXL3A6Qn from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.4.4/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_Z1H9hOrLWa from "/app/node_modules/.pnpm/@nuxt+icon@1.4.5_vite@5.4.1_vue@3.4.38/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import scrollbars_client_NmOOU74Zq1 from "/app/node_modules/.pnpm/@nuxt+ui-pro@1.4.1_vite@5.4.1_vue@3.4.38/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import presets_Svg1t8JqqP from "/app/node_modules/.pnpm/@nuxt+ui-pro@1.4.1_vite@5.4.1_vue@3.4.38/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_kopXyHQMUS from "/app/node_modules/.pnpm/@nuxt+ui-pro@1.4.1_vite@5.4.1_vue@3.4.38/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import sentry_client_a4hAB0MOGi from "/app/apps/app/app/plugins/sentry.client.ts";
export default [
  revive_payload_client_Uf99fU0R6H,
  unhead_5A4gSxIgkz,
  router_Vyzq2DWJ15,
  payload_client_Mu3yatHUFz,
  navigation_repaint_client_qeC8jkeayl,
  check_outdated_build_client_14gNpMUP8B,
  chunk_reload_client_1ehcmAJmE9,
  plugin_vue3_APU4xylc9R,
  components_plugin_KR1HBZs4kY,
  prefetch_client_tAJdqXgwcq,
  session_client_2XxyMLMOkA,
  slideovers_Td48QsuqmF,
  modals_O8BdabQzL2,
  colors_HX0rgKuW8Z,
  plugin_client_zxnXL3A6Qn,
  plugin_Z1H9hOrLWa,
  scrollbars_client_NmOOU74Zq1,
  presets_Svg1t8JqqP,
  variables_kopXyHQMUS,
  sentry_client_a4hAB0MOGi
]